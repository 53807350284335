import React from "react";

import AboutImg from "../../../img/home-about.webp";
import { AboutMark } from "../../../img/svgs";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <section className="my-[180px]">
      <div className="container flex items-center justify-between gap-28">
        <div className="flex shrink-0 items-center justify-center">
          <img
            className="h-[500px] w-full max-w-[600px] rounded-lg object-cover"
            src={AboutImg}
            alt="About"
          />
        </div>

        <div className="flex w-full flex-col items-start justify-start">
          <span className="text-base font-bold uppercase leading-normal tracking-wide text-[#1e212c]">
            Biz haqimizda
          </span>
          <h1 className="text-[46px] font-extrabold leading-[59.80px] text-[#1e212c]">
            MaktabTop nima?
          </h1>

          <ul className="mt-7 flex flex-col gap-3">
            <li className="flex items-center gap-4">
              <AboutMark />
              <span className="text-base font-normal leading-relaxed text-[#414450]">
                Yuqori sifatli maktablar katalogi
              </span>
            </li>
            <li className="flex items-center gap-4">
              <AboutMark />
              <span className="text-base font-normal leading-relaxed text-[#414450]">
                Oson va tez qidiruv
              </span>
            </li>
            <li className="flex items-center gap-4">
              <AboutMark />
              <span className="text-base font-normal leading-relaxed text-[#414450]">
                Maktablar haqida ishonchli ma'lumot
              </span>
            </li>
            <li className="flex items-center gap-4">
              <AboutMark />
              <span className="text-base font-normal leading-relaxed text-[#414450]">
                Ota-onalar va o'qituvchilar uchun qulay platforma
              </span>
            </li>
            <li className="flex items-center gap-4">
              <AboutMark />
              <span className="text-base font-normal leading-relaxed text-[#414450]">
                Foydalanuvchilarning fikrlari
              </span>
            </li>
            <li className="flex items-center gap-4">
              <AboutMark />
              <span className="text-base font-normal leading-relaxed text-[#414450]">
                O'qish uchun keng imkoniyatlar
              </span>
            </li>
            <li className="flex items-center gap-4">
              <AboutMark />
              <span className="text-base font-normal leading-relaxed text-[#414450]">
                Bir necha tilda qo'llab-quvvatlash
              </span>
            </li>
            <li className="flex items-center gap-4">
              <AboutMark />
              <span className="text-base font-normal leading-relaxed text-[#414450]">
                Barcha maktablar bir joyda
              </span>
            </li>
          </ul>

          <Link
            to={"/"}
            className="mt-12 h-[52px] flex-col items-center justify-start gap-2.5 rounded-full bg-[#1089ff] px-10"
          >
            <span className="text-center text-base font-bold leading-[52px] tracking-wide text-white">
              Maktablarni ko‘rish
            </span>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default About;
