import { Routes, Route } from "react-router-dom";
import Home from "./routes/home/Home";
import Schools from "./routes/schools/Schools";
import { Analytics } from "@vercel/analytics/react";
import School from "./routes/school/School";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/schools" element={<Schools />} />
        <Route path="/school" element={<School />} />
      </Routes>

      <Analytics />
    </div>
  );
}

export default App;
