import React, { useEffect } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import SchoolSection from "../../components/schoolspage/schools/SchoolsSection";
import Reviews from "../../components/homepage/reviews/Reviews";
import Miss from "../../components/homepage/miss/Miss";

const Schools = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = "https://www.maktabtop.uz";
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Header />

      <SchoolSection />
      <Reviews />
      <Miss />

      <Footer />
    </>
  );
};

export default Schools;
