import React, { useEffect, useState } from "react";
import {
  Star,
  MapPin,
  Phone,
  Mail,
  Globe,
  Calendar,
  Users,
  Award,
  X,
} from "lucide-react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

const SingleSchool = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = "https://www.maktabtop.uz";
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const [selectedImage, setSelectedImage] = useState(null);

  const school = {
    id: 1,
    name: "Future Bright Academy",
    address: "Toshkent sh., Yakkasaroy tumani, Mustaqillik ko'chasi, 25-uy",
    phone: "+998 71 123 45 67",
    email: "info@futurebright.uz",
    programs: [
      "Milliy o'quv dasturi",
      "Cambridge A-Level",
      "IB Diploma Program",
    ],
    tuitionFee: "12,000,000 UZS / yil",
    admissionRequirements: "Test sinovlari va suhbatdan muvaffaqiyatli o'tish",
    studentCapacity: 500,
    languages: ["O'zbek tili", "Ingliz tili", "Rus tili"],
    website: "https://futurebright.uz",
    facilities: [
      "Sport zali",
      "Kutubxona",
      "STEM laboratoriyasi",
      "Bolalar o'yin maydonchasi",
    ],
    ratings: {
      average: 4.8,
      reviews: [
        {
          reviewer: "Otabek T.",
          comment: "O'quvchilarga ajoyib imkoniyatlar yaratilgan!",
          rating: 5,
        },
        {
          reviewer: "Lola X.",
          comment: "Maktab joylashuvi qulay va ta'lim darajasi yuqori.",
          rating: 4.5,
        },
      ],
    },
    principal: "Mahmudov Jamshid Anvarovich",
    establishedYear: 2015,
    extracurricularActivities: [
      "Robototexnika",
      "San'at va musiqa to'garaklari",
      "Sport musobaqalari",
      "Matematika olimpiadasi",
    ],
    images: [
      "https://avatars.mds.yandex.net/get-altay/7368569/2a0000018426ed6eb23001959faf8aa67d05/XXL_height",
      "https://static.tildacdn.one/tild3964-3038-4639-b439-326435396236/1.jpg",
      "https://static.tildacdn.one/tild3031-6537-4264-a266-623961663461/2.jpg",
      "https://static.tildacdn.one/tild3562-6166-4661-b734-323439386339/3.jpg",
      "https://yandex-images.clstorage.net/4JcA72w86/0eebefMB-6/1kw_4ikrTZG6OpP9InVmCx5_KluKgI8jGukgeUqF4anc6a7XHR8lOSln5czN5L4qZyCLmCCfhax5iGCvImlS-5BZPPU-cgvH_G3Yu20uMBpUPmRanIyQUivNFRRyr8fN-syUzwVed21ofK6LppSsPDRa-thS5N_vCYMNqkYPBozgU3S0VzNZlq04kvhVUmM_VH4pcrSyhGwHwT87fZPb5cr9ovIXZ0BtXDiamYqx2SEvaPHwRPbz7TuLAaFELh-c73tXvGMNTJu_IpD-X1B3PXh_OnirkKNgBdoQFHeF_sL77LXQPmJ2R2kli7q7jcIgAGThwGjDxPEiph2pcxkGnsRxVaJJEmKXphC4xGJzdzxCdCZsl7KBaQH5MTF1tvzd3uyb9AtJZmFIJ5GmrIbFIxZh0v173PbNBa88h109IqvceHC4Tit1oKk_p8xATHc6dkENU4mqsHkA5TgjX7nB9u7Pv_Ivc0lSdB-1nZis3y0Oe_fNc_nyzxKlN6NqKwG70E5hqVAcXoqgF4XmfG9zD1NlGn-HgrhhJ-4-G325wsLvyY_hPlZxakI0qoesndkSPWXj4EPExdIhoT20SCgkvORJbL1FBkmCrxSIzX5VSTVXXCRzpp-STxvUHRVmi_jQ8Oav9wxAZV54NY6HjZrrMxN2wcByzMbYLI4woHkNCabrdG65ajx4lpQQmcZBYHMKVFQsfJ2TsXwM1iMRQ6HGzfLMjNklWFp_YyakqquP4jwDU_nRftrr0y6lEI1mOSSA3lhItUsdXZutAbD3XWBgInFyGWS6s6NjJvgzAmKM_sfX6ZnYAEFEW0QqioagiMAfJXbI-kDB6N4hjQ2LRDIcnd5pUYh8GkqhoQ-Z90dbYxVTfjxghK-TfQDEBi9CsNjF_-G77xN7ekZVEJuvqJL1HzBFzOBJ39DKHrksnH8JB6rgc2GcfDJqtqMsh9lpSEs9Q3IzcJiitnQ-6DgfWI4",
      "https://avatars.mds.yandex.net/get-altay/11203687/2a000001912b5bca186777a242b3e8bf384d/XXL_height",
    ],
    map: "https://yandex.uz/maps/-/CHQeeV~E",
  };

  return (
    <>
      <Header />

      <main className="mb-16 mt-24">
        <div className="container mx-auto px-4">
          {/* Hero Section */}
          <section className="mb-8">
            <h1 className="mb-6 text-4xl font-black text-[#1e212c] md:text-5xl">
              {school.name}
            </h1>
            <div className="mb-4 flex items-center gap-4 text-gray-600">
              <div className="flex items-center gap-1">
                <Star className="h-5 w-5 text-yellow-400" />
                <span className="font-semibold">{school.ratings.average}</span>
              </div>
              <div className="flex items-center gap-1">
                <MapPin className="h-5 w-5" />
                <span>{school.address}</span>
              </div>
            </div>
          </section>

          {/* Image Gallery Section */}
          <section className="mb-12">
            <div className="grid grid-cols-2 gap-4 md:grid-cols-3">
              {school.images.map((image, index) => (
                <div
                  key={index}
                  className="relative h-48 cursor-pointer overflow-hidden rounded-lg md:h-64"
                  onClick={() => setSelectedImage(image)}
                >
                  <img
                    src={image}
                    alt={`School image ${index + 1}`}
                    className="h-full w-full object-cover transition-transform duration-300 hover:scale-110"
                  />
                </div>
              ))}
            </div>

            {/* Modal for full-size image view */}
            {selectedImage && (
              <div
                className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75 p-4"
                onClick={() => setSelectedImage(null)}
              >
                <div className="relative w-full max-w-4xl">
                  <button
                    className="absolute right-4 top-4 text-white hover:text-gray-300"
                    onClick={() => setSelectedImage(null)}
                  >
                    <X className="h-6 w-6" />
                  </button>
                  <img
                    src={selectedImage}
                    alt="Full size view"
                    className="h-auto w-full rounded-lg"
                  />
                </div>
              </div>
            )}
          </section>

          {/* Contact Information */}
          <section className="mb-12 grid grid-cols-1 gap-6 md:grid-cols-3">
            <div className="rounded-lg bg-white p-6 shadow-lg">
              <div className="flex items-center gap-3">
                <Phone className="h-6 w-6 text-blue-600" />
                <div>
                  <h3 className="mb-1 font-semibold">Telefon</h3>
                  <p>{school.phone}</p>
                </div>
              </div>
            </div>
            <div className="rounded-lg bg-white p-6 shadow-lg">
              <div className="flex items-center gap-3">
                <Mail className="h-6 w-6 text-blue-600" />
                <div>
                  <h3 className="mb-1 font-semibold">Email</h3>
                  <p>{school.email}</p>
                </div>
              </div>
            </div>
            <div className="rounded-lg bg-white p-6 shadow-lg">
              <div className="flex items-center gap-3">
                <Globe className="h-6 w-6 text-blue-600" />
                <div>
                  <h3 className="mb-1 font-semibold">Veb-sayt</h3>
                  <a
                    href={school.website}
                    className="text-blue-600 hover:underline"
                  >
                    {school.website}
                  </a>
                </div>
              </div>
            </div>
          </section>

          {/* Key Information */}
          <section className="mb-12 grid grid-cols-1 gap-8 md:grid-cols-2">
            <div>
              <h2 className="mb-6 text-2xl font-bold">Maktab haqida</h2>
              <div className="space-y-4">
                <div className="flex items-center gap-2">
                  <Calendar className="h-5 w-5 text-blue-600" />
                  <p>Tashkil etilgan yili: {school.establishedYear}</p>
                </div>
                <div className="flex items-center gap-2">
                  <Users className="h-5 w-5 text-blue-600" />
                  <p>O'quvchilar soni: {school.studentCapacity}</p>
                </div>
                <div>
                  <h3 className="mb-2 font-semibold">O'quv dasturlari:</h3>
                  <ul className="list-inside list-disc space-y-1">
                    {school.programs.map((program, index) => (
                      <li key={index}>{program}</li>
                    ))}
                  </ul>
                </div>
                <div>
                  <h3 className="mb-2 font-semibold">Ta'lim tillari:</h3>
                  <div className="flex gap-2">
                    {school.languages.map((language, index) => (
                      <span
                        key={index}
                        className="rounded-full bg-blue-100 px-3 py-1 text-sm text-blue-800"
                      >
                        {language}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div>
              <h2 className="mb-6 text-2xl font-bold">
                Qo'shimcha ma'lumotlar
              </h2>
              <div className="space-y-4">
                <div>
                  <h3 className="mb-2 font-semibold">O'quv to'lovi:</h3>
                  <p>{school.tuitionFee}</p>
                </div>
                <div>
                  <h3 className="mb-2 font-semibold">Qabul talablari:</h3>
                  <p>{school.admissionRequirements}</p>
                </div>
                <div>
                  <h3 className="mb-2 font-semibold">Direktor:</h3>
                  <p>{school.principal}</p>
                </div>
              </div>
            </div>
          </section>

          {/* Facilities and Activities */}
          <section className="mb-12 grid grid-cols-1 gap-8 md:grid-cols-2">
            <div>
              <h2 className="mb-6 text-2xl font-bold">Maktab jihozlari</h2>
              <ul className="grid grid-cols-2 gap-4">
                {school.facilities.map((facility, index) => (
                  <li
                    key={index}
                    className="flex items-center gap-2 rounded-lg bg-gray-50 p-3 shadow"
                  >
                    <Award className="h-5 w-5 text-blue-600" />
                    {facility}
                  </li>
                ))}
              </ul>
            </div>

            <div>
              <h2 className="mb-6 text-2xl font-bold">
                Qo'shimcha mashg'ulotlar
              </h2>
              <ul className="grid grid-cols-2 gap-4">
                {school.extracurricularActivities.map((activity, index) => (
                  <li
                    key={index}
                    className="flex items-center gap-2 rounded-lg bg-gray-50 p-3 shadow"
                  >
                    <Award className="h-5 w-5 text-blue-600" />
                    {activity}
                  </li>
                ))}
              </ul>
            </div>
          </section>

          {/* Reviews */}
          <section className="mb-12">
            <h2 className="mb-6 text-2xl font-bold">Fikr-mulohazalar</h2>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
              {school.ratings.reviews.map((review, index) => (
                <div key={index} className="rounded-lg bg-white p-6 shadow-lg">
                  <div className="mb-4 flex items-center justify-between">
                    <h3 className="font-semibold">{review.reviewer}</h3>
                    <div className="flex items-center gap-1">
                      <Star className="h-5 w-5 text-yellow-400" />
                      <span>{review.rating}</span>
                    </div>
                  </div>
                  <p className="text-gray-600">{review.comment}</p>
                </div>
              ))}
            </div>
          </section>

          {/* Map Section */}
          <section className="mb-12">
            <h2 className="mb-6 text-2xl font-bold">Maktab joylashuvi</h2>
            <div className="overflow-hidden rounded-lg shadow-lg">
              <div style="position:relative;overflow:hidden;">
                <a
                  href="https://yandex.uz/maps/10335/tashkent/?utm_medium=mapframe&utm_source=maps"
                  style="color:#eee;font-size:12px;position:absolute;top:0px;"
                >
                  Ташкент
                </a>
                <a
                  href="https://yandex.uz/maps/10335/tashkent/house/YkAYdARgTEYBQFprfX52cXRjYA==/?azimuth=5.575509211542039&ll=69.232410%2C41.270720&utm_medium=mapframe&utm_source=maps&z=18.36"
                  style="color:#eee;font-size:12px;position:absolute;top:14px;"
                >
                  Улица Богибустан, 208 — Яндекс Карты
                </a>
                <iframe
                  src="https://yandex.uz/map-widget/v1/?azimuth=5.575509211542039&ll=69.232410%2C41.270720&mode=whatshere&utm_medium=mapframe&utm_source=maps&whatshere%5Bpoint%5D=69.230511%2C41.270900&whatshere%5Bzoom%5D=17&z=18.36"
                  width="560"
                  height="400"
                  frameBorder="1"
                  allowFullScreen="true"
                  style="position:relative;"
                ></iframe>
              </div>
              <div className="relative overflow-hidden">
                <a
                  href="https://yandex.uz/maps/org/132543039073/?utm_medium=mapframe&utm_source=maps"
                  className="absolute top-0 text-xs text-[#eee]"
                >
                  Ташкент Сити парк
                </a>
                <a
                  href="https://yandex.uz/maps/10335/tashkent/category/park/184106346/?utm_medium=mapframe&utm_source=maps"
                  className="absolute top-[14px] text-xs text-[#eee]"
                >
                  Парк культуры и отдыха в Ташкенте
                </a>
                <iframe
                  src="https://yandex.uz/map-widget/v1/?azimuth=6.248415075987515&ll=69.252940%2C41.315254&mode=poi&poi%5Bpoint%5D=69.248387%2C41.316428&poi%5Buri%5D=ymapsbm1%3A%2F%2Forg%3Foid%3D132543039073&z=16.26"
                  width="100%"
                  height="400"
                  frameBorder="1"
                  allowFullScreen="true"
                  className="relative"
                ></iframe>
              </div>
            </div>
          </section>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default SingleSchool;
