import React from "react";
import { FaFacebook, FaInstagram, FaTelegram, FaTwitter } from "react-icons/fa";
import { FooterMail, FooterPhone } from "../../img/svgs";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-[#1e212c] pb-10 pt-20 text-white">
      <div className="container mx-auto px-6">
        <div className="flex items-start justify-start gap-20">
          <div className="flex w-full max-w-[250px] flex-col gap-4">
            <NavLink
              to={"/"}
              className="text-2xl font-bold leading-[38.40px] text-[#f8f8f8]"
            >
              MaktabTop
            </NavLink>
            <p className="text-[#FFFFFF99]">
              O'zbekistondagi eng to'liq xususiy maktablar ma'lumotlar bazasi.
              Bizning platformamiz orqali maktablarni topish, taqqoslash va
              sharhlash mumkin.
            </p>
          </div>

          <div className="flex flex-col gap-3">
            <span className="text-base font-bold uppercase leading-normal tracking-wide text-white">
              Sayt Xaritasi
            </span>
            <ul className="flex flex-col items-start gap-2">
              <li>
                <NavLink
                  to={"/about"}
                  className="text-base font-normal leading-relaxed text-[#FFFFFF99]"
                >
                  Bosh Sahifa
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/about"}
                  className="text-base font-normal leading-relaxed text-[#FFFFFF99]"
                >
                  Biz Haqimizda
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/schools"}
                  className="text-base font-normal leading-relaxed text-[#FFFFFF99]"
                >
                  Maktablar
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/contact"}
                  className="text-base font-normal leading-relaxed text-[#FFFFFF99]"
                >
                  Bog‘lanish
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="flex flex-col gap-3">
            <span className="text-base font-bold uppercase leading-normal tracking-wide text-white">
              Biz Bilan Bog‘lanish
            </span>

            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-2">
                <FooterPhone />
                <span className="text-base font-normal leading-relaxed text-[#FFFFFF99]">
                  +998 93 460 76 65
                </span>
              </div>
              <div className="flex items-center gap-2">
                <FooterMail />
                <span className="text-base font-normal leading-relaxed text-[#FFFFFF99]">
                  info@talimhub.uz
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-8 border-t border-gray-700 pt-4 text-center text-gray-500">
        <p>&copy; 2024 MaktabTop. Barcha huquqlar himoyalangan.</p>
      </div>
    </footer>
  );
};

export default Footer;
